var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',{attrs:{"title":"Settlement List"}},[_c('div',{staticClass:"custom-search d-flex justify-content-start"},[_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-left"},[_c('span',{staticClass:"text-warning pl-1"},[_c('strong',[_vm._v("Total Invoice: "+_vm._s(_vm.totalInvoice))])]),_c('span',{staticClass:"text-info pl-1"},[_c('strong',[_vm._v("Pending Amount: "+_vm._s(_vm.pending_amount))])]),_c('span',{staticClass:"text-danger pl-1"},[_c('strong',[_vm._v("Payable Amount: "+_vm._s(_vm.total_payable_amount))])])])])],1),_c('div',{staticClass:"custom-search d-flex justify-content-end"},[_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{staticClass:"mr-1"},[_vm._v("Search")]),_c('b-form-input',{staticClass:"d-inline-block",attrs:{"placeholder":"Search","type":"text"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1)])],1),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"line-numbers":false,"rtl":_vm.direction,"search-options":{
      enabled: true,
      externalQuery: _vm.searchTerm,
    },"pagination-options":{
      enabled: true,
      perPage: _vm.pageLength,
    },"theme":"my-theme"},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'id')?_c('span',{staticClass:"text-nowrap"},[_c('span',[_vm._v(_vm._s(props.row.id))])]):_vm._e(),(props.column.field == 'company_name')?_c('span',{staticClass:"text-nowrap"},[_c('span',[_vm._v(_vm._s(props.row.company_name))])]):_vm._e(),(props.column.field == 'total_invoice')?_c('span',{staticClass:"text-nowrap"},[_c('b-link',{staticClass:"font-weight-bold",attrs:{"to":{
            name: 'admin-new-settlement',
            params: { ispId: props.row.id },
          }}},[_c('span',[_vm._v(_vm._s(props.row.total_invoice))])])],1):_vm._e(),(props.column.field == 'pending_amount')?_c('span',{staticClass:"text-nowrap"},[_c('span',[_vm._v(_vm._s(props.row.pending_amount))])]):_vm._e(),(props.column.field == 'charge_amount')?_c('span',{staticClass:"text-nowrap"},[_c('span',[_vm._v(_vm._s(props.row.charge_amount))])]):_vm._e(),(props.column.field == 'total_payable_amount')?_c('span',{staticClass:"text-nowrap"},[_c('span',[_vm._v(_vm._s(props.row.total_payable_amount))])]):_vm._e(),(props.column.field == 'actions')?_c('div',{staticClass:"text-nowrap"},[_c('feather-icon',{staticClass:"mx-1",attrs:{"id":("invoice-row-" + (props.row.id) + "-preview-icon"),"icon":"Edit2Icon","size":"16"},on:{"click":function($event){return _vm.$router.push({
              name: 'account-setting',
              query: { isp_id: props.row.id },
            })}}})],1):_vm._e()]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(" Showing 1 to ")]),_c('b-form-select',{staticClass:"mx-1",attrs:{"options":['100', '300', '500', '1000']},on:{"input":function (value) { return props.perPageChanged({ currentPerPage: value }); }},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(" of "+_vm._s(props.total)+" entries ")])],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":1,"total-rows":props.total,"per-page":_vm.pageLength,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value) { return props.pageChanged({ currentPage: value }); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }